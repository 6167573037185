const Separator = '/';

//Page name only
export const Page = {
  Login: 'login',
  Dashboard: 'dashboards',
  DashboardsS3: 'dashboard',
  MyCases: 'cases',
  Case: 'case',
  CaseTest: 'casetest',
  AccSettings: 'settings',
  AccSettingsS3: 'setting',
  File: 'files',
  pDocs: 'case/p',
  pFDocs: 'case/f',
  PIIPSTDocs: 'PII/p',
  PIIPSTFiles: 'PII/f',
  eFile: 'efiles',
  PDF: 'view',
  PDFViwer: 'viewer',
  Views: 'views',
  Edit: 'edit',
  Search: 'search',
  CaseSettings: 'case-settings',
  eView: 'eview',
  Analysis: 'Analysis',
  PII: 'PII',
  PIIFile: 'PII/files',
  PIIResult: 'PII/result',
  PIIview: 'PII/view',
  PIIFileView: 'PII/files/view',
  PIIFileResult: 'PII/files/result',
  PIIReview: 'PII/review',
  PIIeReview: 'PII/ereview',
  PIIeFile: 'PII/efiles',
  AdvanceSearch: 'Advancesearch',
  DocViewer: 'Documentview',
  mView: 'mview',
  About: 'about',
  EditorialClaims: 'editorial-guideline',

  //S3 Pages
  MyCasesS3: 'cases',
  CaseS3: 'folder',
  ImagePorcessS3: 'image',
  CaseTestS3: 'casetest',
  FileS3: 'files',
  pDocsS3: 'case/p',
  pFDocsS3: 'case/f',
  PIIPSTDocsS3: 'PII/p',
  PIIPSTFilesS3: 'PII/f',
  eFileS3: 'efiles',
  PDFS3: 'view',
  PDFS3Viwer: 'viewer',
  ViewsS3: 'views',
  ImageViewS3: 'image/view',
  EditS3: 'edit',
  SearchS3: 'search',
  CaseSettingsS3: 'case-setting',
  eViewS3: 'eview',
  AnalysisS3: 'Analysis',
  PIIS3: 'PII',
  PIIFileS3: 'PII/files',
  PIIResultS3: 'PII/result',
  PIIviewS3: 'PII/view',
  PIIFileViewS3: 'PII/files/view',
  PIIFileResultS3: 'PII/files/result',
  PIIReviewS3: 'PII/review',
  PIIeReviewS3: 'PII/ereview',
  PIIeFileS3: 'PII/efiles',
  AdvanceSearchS3: 'Advancesearch',
  DocViewerS3: 'Documentview',
  copyPaste: 'copyPaste',
  RealTime: 'realTime',
  SearchImage: 'search-image',
  // About Paths
  rules: 'rules',
  LoginLogs: 'login-logs',
  appFeatures: 'features',
  changeLogs: 'change-logs'
};





//Full url of each Page
export const eDiscoveryUrl = {
  mView:Separator + Page.mView,
  Login: Separator + Page.Login,
  Dashboard: Separator + Page.Dashboard,
  MyCases: Separator + [Page.Dashboard, Page.MyCases].join(Separator),
  Case: Separator + [Page.Dashboard, Page.Case].join(Separator),
  CaseTest: Separator + [Page.Dashboard, Page.CaseTest].join(Separator),
  File: Separator + [Page.Dashboard, Page.File].join(Separator),
  pDocs: Separator + [Page.Dashboard, Page.pDocs].join(Separator),
  PIIPSTDocs: Separator + [Page.Dashboard, Page.PIIPSTDocs].join(Separator),
  PIIPSTFiles: Separator + [Page.Dashboard, Page.PIIPSTFiles].join(Separator),
  pFDocs: Separator + [Page.Dashboard, Page.pFDocs].join(Separator),
  eFile: Separator + [Page.Dashboard, Page.eFile].join(Separator),
  Edit: Separator + [Page.Dashboard, Page.Edit].join(Separator),
  PDF: Separator + [Page.Dashboard, Page.PDF].join(Separator),
  Search: Separator + [Page.Dashboard, Page.Search].join(Separator),
  CaseSettings: Separator + [Page.CaseSettings],
  AccSettings: Separator + Page.AccSettings,
  eView: Separator + [Page.Dashboard, Page.eView].join(Separator),
  Analysis: Separator + [Page.Dashboard, Page.Analysis].join(Separator),
  PII: Separator + [Page.Dashboard, Page.PII].join(Separator),
  PIIFile: Separator + [Page.Dashboard, Page.PIIFile].join(Separator),
  PIIview: Separator + [Page.Dashboard, Page.PIIview].join(Separator),
  PIIFileView: Separator + [Page.Dashboard, Page.PIIFileView].join(Separator),
  PIIResult: Separator + [Page.Dashboard, Page.PIIResult].join(Separator),
  PIIFileResult: Separator + [Page.Dashboard, Page.PIIFileResult].join(Separator),
  PIIReview: Separator + [Page.Dashboard, Page.PIIReview].join(Separator),
  PIIeReview: Separator + [Page.Dashboard, Page.PIIeReview].join(Separator),
  PIIeFile: Separator + [Page.Dashboard, Page.PIIeFile].join(Separator),
  AdvanceSearch: Separator + [Page.Dashboard, Page.AdvanceSearch].join(Separator),
  // DocViewer: Separator + [Page.Dashboard, Page.DocViewer].join(Separator),
  DocViewer: Separator + [Page.DocViewer].join(Separator),

  //Stage 2 Pages
  DashboardsS3: Separator + Page.DashboardsS3,
  copyPaste: Separator + [Page.DashboardsS3, Page.copyPaste].join(Separator),
  MyCasesS3: Separator + [Page.DashboardsS3, Page.MyCases].join(Separator),
  CaseS3: Separator + [Page.DashboardsS3, Page.CaseS3].join(Separator),
  ImagePorcessS3: Separator + [Page.DashboardsS3, Page.ImagePorcessS3].join(Separator),
  SearchImage: Separator + [Page.DashboardsS3, Page.SearchImage].join(Separator),
  CaseTestS3: Separator + [Page.DashboardsS3, Page.CaseTest].join(Separator),
  FileS3: Separator + [Page.DashboardsS3, Page.File].join(Separator),
  pDocsS3: Separator + [Page.DashboardsS3, Page.pDocs].join(Separator),
  PIIPSTDocsS3: Separator + [Page.DashboardsS3, Page.PIIPSTDocs].join(Separator),
  PIIPSTFilesS3: Separator + [Page.DashboardsS3, Page.PIIPSTFiles].join(Separator),
  pFDocsS3: Separator + [Page.DashboardsS3, Page.pFDocs].join(Separator),
  eFileS3: Separator + [Page.DashboardsS3, Page.eFile].join(Separator),
  EditS3: Separator + [Page.DashboardsS3, Page.Edit].join(Separator),
  PDFS3: Separator + [Page.DashboardsS3, Page.PDF].join(Separator),
  ViewsS3: Separator + [Page.DashboardsS3, Page.ViewsS3].join(Separator),
  ImageViewS3: Separator + [Page.DashboardsS3, Page.ImageViewS3].join(Separator),
  SearchS3: Separator + [Page.DashboardsS3, Page.Search].join(Separator),
  CaseSettingsS3: Separator + [Page.CaseSettingsS3],
  AccSettingsS3: Separator + Page.AccSettingsS3,
  eViewS3: Separator + [Page.DashboardsS3, Page.eView].join(Separator),
  AnalysisS3: Separator + [Page.DashboardsS3, Page.Analysis].join(Separator),
  PIIS3: Separator + [Page.DashboardsS3, Page.PII].join(Separator),
  PIIFileS3: Separator + [Page.DashboardsS3, Page.PIIFile].join(Separator),
  PIIviewS3: Separator + [Page.DashboardsS3, Page.PIIview].join(Separator),
  PIIFileViewS3: Separator + [Page.DashboardsS3, Page.PIIFileView].join(Separator),
  PIIResultS3: Separator + [Page.DashboardsS3, Page.PIIResult].join(Separator),
  PIIFileResultS3: Separator + [Page.DashboardsS3, Page.PIIFileResult].join(Separator),
  PIIReviewS3: Separator + [Page.DashboardsS3, Page.PIIReview].join(Separator),
  PIIeReviewS3: Separator + [Page.DashboardsS3, Page.PIIeReview].join(Separator),
  PIIeFileS3: Separator + [Page.DashboardsS3, Page.PIIeFile].join(Separator),
  AdvanceSearchS3: Separator + [Page.DashboardsS3, Page.AdvanceSearch].join(Separator),
  // DocViewerS3: Separator + [Page.DashboardsS3, Page.DocViewer].join(Separator),
  DocViewerS3: Separator + [Page.DocViewer].join(Separator),
  PDFS3Viwer: Separator + [Page.DashboardsS3, Page.PDFS3Viwer].join(Separator),

  //About Paths
  Rules: Separator + Page.rules,
  LoginLogs: Separator + Page.LoginLogs,
  EditorialClaims: Separator + Page.EditorialClaims,
  appFeatures: Separator + Page.appFeatures,
  changeLogs: Separator + Page.changeLogs,

};